// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../stylesheets/application.css";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import Alpine from "alpinejs";
window.Alpine = Alpine;

Alpine.start();
Rails.start();
ActiveStorage.start();

function initSidebar() {
    const $sidebarBtn = document.querySelector(".js-toggle-sidebar");
    const $sidenav = document.querySelector(".js-side-nav");
    const instances = tippy("[data-tippy-content]");

    if ($sidebarBtn && $sidenav) {
        $sidebarBtn.addEventListener("click", function () {
            const isOpen = $sidenav.classList.contains("is-open");
            if (isOpen) {
                $sidenav.classList.remove("is-open");
                instances.forEach((i) => {
                    i.enable();
                });
            } else {
                $sidenav.classList.add("is-open");
                instances.forEach((i) => {
                    i.disable();
                });
            }
        });
    }
}

function init() {
    initSidebar();
    window?.feather?.replace();
}

document.addEventListener("DOMContentLoaded", init);
